import React, { useState } from "react";
import { Formik, FormikProps, Form } from "formik";
import DatosPersonales from "../../components/DatosPersonales";
import Disponibilidad from "../../components/Disponibilidad";
import { FieldProps, SelectProps } from "../../components/Fields";
import Formacion from "../../components/Formacion";
import Preferencias from "../../components/Preferencias";
import { FormikVoluntario } from "../../services/VoluntarioService";
import Map from "../../components/Map";
import InscripcionService from "../../services/InscripcionService";
import { useNavigate } from "react-router-dom";
import Routes from "../Routes";
import { AxiosError } from "axios";
import NotificationModal from "../../components/NotificationModal";
import LoadingAnimation from "../../assets/loading-animation.svg";
import '../../styles/form.scss';

interface FormikVoluntarioErrors {
    nombre: string
    apellidos: string
    genero: string
    fechanacimiento: string
    modalidad: string
    datosPersonales: {
        tipoDocumento?: string
        lugarNacimiento?: string
        nacionalidad?: string
        direccion?: string
        telefono1?: string
        telefono2?: string
        correoelectronico?: string
        carnet?: string
        situacionLabora?: string
        profesion?: string
    }
    formacion: {
        nivelEstudios?: string
        idiomas?: string
        hablasEspañol?: string  
        experenciaAnterior?: string
        experenciaLaboral?: string
    }
    preferencias: {
        nuestra?: string
        motivacionparaCambio?: string
        hobby?: string
        perfilAtencion?: string
        areaTrabajo?: string
        observaciones?: string
    }
    disponibilidad: {
        lunes?: string
        martes?: string
        miercoles?: string
        jueves?: string
        viernes?: string
        sabado?: string
        domingo?: string
        horarios?: {
            manana?: string
            tarde?: string
            frecuencia?: string
            dedicacion?: string
            preferenciacontacto?: string
            tipoVoluntario?: string
            observaciones?: string
        }
    }
}

interface LatLng {
    lat: number;
    lng: number;
}

enum TitleEnum {
    datos="Datos personales",
    formacion="Formación",
    preferencias="Preferencias",
    disponibilidad="Disponibilidad"
}

const initialValues: FormikVoluntario = {
    nombre: "",
    apellidos: "",
    genero: "",
    fechanacimiento: "",
    modalidad: "",
    datosPersonales: {
        tipoDocumento: "",
        tipoDocumentoValue: "",
        lugarNacimiento: {
            id: "",
            value: ""
        },
        nacionalidad: {
            id: "",
            value: ""
        },
        direccion: "",
        telefono1: "",
        telefono2: "",
        correoelectronico: "",
        carnet: false,
        situacionLabora: "",
        profesion: ""
    },
    formacion: {
        nivelEstudios: "",
        idiomas: "",
        hablasEspanol: false,
        experenciaAnterior: false,
        experenciaLaboral: false,
        sector: ""
    },
    preferencias: {
        nuestra: "",
        motivacionparaCambio: "Sin informar",
        hobby: false,
        hobbyValue: "",
        perfilAtencion: "",
        areaTrabajo: "",
        observaciones: ""
    },
    disponibilidad: {
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false,
        sabado: false,
        domingo: false,
        horarios: {
            manana: false,
            tarde: false,
            frecuencia: "",
            dedicacion: "",
            preferenciacontacto: "",
            tipoVoluntariado: "",
            observaciones: ""
        }
    }
}

export default function FormularioInscripcionScreen() {
    const navigate = useNavigate()
    const [selected, setSelected] = useState("Datos personales")
    const [defaultMarker, setDefaultMarker] = useState<LatLng>()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState({ header: '', body: '' })

    const validate = (values: FormikVoluntario) => {
        const { nombre, apellidos, genero, modalidad, datosPersonales, preferencias, disponibilidad: { horarios: { frecuencia } }} = values
        const { tipoDocumento, lugarNacimiento, nacionalidad, direccion, situacionLabora, profesion } = datosPersonales
        const { nuestra, motivacionparaCambio, perfilAtencion, areaTrabajo } = preferencias
        const errors: Partial<FormikVoluntarioErrors> = {}

        if (!nombre) { errors.nombre = "* Por favor, introduzca su nombre" }
        if (!apellidos) { errors.apellidos = "* Por favor, Introduzca su apellido" }
        if (genero === "0") { errors.genero = "* Por favor, Elegir su genero" }
        if (modalidad === "0") { errors.modalidad = "* Por favor, Elegir su modalidad" }
        
        // Datos personales
        if (tipoDocumento === "0" || !lugarNacimiento.value || !nacionalidad.value || !direccion || !profesion || situacionLabora === "0") {
            errors.datosPersonales = {}
        
            if (tipoDocumento === "0") { errors.datosPersonales.tipoDocumento = "* Por favor, Introduzca su Tipo de documento" }
            if (!lugarNacimiento.value) { errors.datosPersonales.lugarNacimiento = "* Por favor, Introduzca su Lugar de nacimiento" }
            if (!nacionalidad.value) { errors.datosPersonales.nacionalidad = "* Por favor, Introduzca su nacionalidad" }
            if (!direccion) { errors.datosPersonales.direccion = "* Por favor, Introduzca su dirección" }
            if (!profesion) { errors.datosPersonales.profesion = "* Por favor, Introduzca su profesion" }
            if (situacionLabora === "0") { errors.datosPersonales.situacionLabora = "* Por favor, Introduzca su situacion labora" }
        }

        
        // Preferencias
        if (nuestra === "0" || !motivacionparaCambio || !perfilAtencion || !areaTrabajo) {
            errors.preferencias = {}

            if (nuestra === "0") { errors.preferencias.nuestra = "* Por favor, Introduzca su nuestra" }
            if (!motivacionparaCambio) { errors.preferencias.motivacionparaCambio = "* Por favor, Introduzca su movitacion para el cambio" }
            if (!perfilAtencion) { errors.preferencias.perfilAtencion = "* Por favor, Introduzca su perfil de Atencion" }
            if (!areaTrabajo) { errors.preferencias.areaTrabajo = "* Por favor, Introduzca su area de trabajo" }
        }
        
        // disponibilidad
        if (frecuencia === "0") {
            errors.disponibilidad = { horarios: { frecuencia: "* Por favor, Introduzca su frecuencia" }}
        }

        return errors;
    }

    const onHeaderClick = (title: string) => {
        setSelected(title)
    }

    const submitForm = async (values: FormikVoluntario) => {
        try {
            setLoading(true)
            await InscripcionService.register(values)
            navigate(Routes.CONFIRMACION, { state: { key: Math.random() }})
            setLoading(false)
        } catch (err) {
            const error = err as AxiosError
            setErrorMessage({ body: error.message, header: 'Error de Servicio' })
        }
    }

    return (
        <div className="App-form">
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={submitForm}
            >
                {({ values, touched, errors }: FormikProps<FormikVoluntario>) => (
                    <Form>
                        <div className="form-title">
                            <HeaderTitle text="Formulario" selected="Formulario" />
                        </div>

                        <div className="form-top">
                            <FieldProps 
                                name="nombre"
                                id="nombre"
                                type="text"
                                placeholder="Nombre"
                                label="Nombre"
                                value={values.nombre}
                                errors={errors.nombre}
                                touched={touched.nombre}
                                relevant={true}
                            />

                            <FieldProps 
                                name="apellidos"
                                id="apellidos"
                                type="text"
                                placeholder="Apellidos"
                                label="Apellidos"
                                value={values.apellidos}
                                errors={errors.apellidos}
                                touched={touched.apellidos}
                                relevant={true}
                            />

                            <SelectProps
                                name="genero"
                                id="genero"
                                relevant={true}
                                value={values.genero.toString()}
                                errors={errors.genero}
                                touched={touched.genero}
                                label="Genero"
                            >
                                <option value="33905">Hombre</option>
                                <option value="33908">Indeterminado</option>
                                <option value="33906">Mujer</option>
                                <option value="33907">No binario</option>
                            </SelectProps>

                            <FieldProps 
                                name="fechanacimiento"
                                id="fechanacimiento"
                                type="date"
                                label="Fecha de Nacimiento"
                                placeholder="Fecha de Nacimiento"
                                value={values.fechanacimiento}
                                touched={touched.fechanacimiento}
                                errors={errors.fechanacimiento}
                            />

                            <SelectProps
                                name="modalidad"
                                id="modalidad"
                                relevant={true}
                                value={values.modalidad.toString()}
                                errors={errors.modalidad}
                                touched={touched.modalidad}
                                label="Modalidad"
                            >
                                <option value="34474">Voluntario para eventos</option>
                                <option value="34475">Voluntario internacional</option>
                                <option value="34476">Voluntario presencial</option>
                                <option value="34477">Voluntario online</option>
                            </SelectProps>
                        </div>

                        <div className="form-header">
                            <HeaderTitle 
                                text={TitleEnum.datos}
                                selected={selected} 
                                onClick={() => onHeaderClick(TitleEnum.datos)} 
                            />
                            <HeaderTitle 
                                text={TitleEnum.formacion} 
                                selected={selected} 
                                onClick={() => onHeaderClick(TitleEnum.formacion)} 
                            />
                            <HeaderTitle 
                                text={TitleEnum.preferencias} 
                                selected={selected} 
                                onClick={() => onHeaderClick(TitleEnum.preferencias)} 
                            />
                            <HeaderTitle 
                                text={TitleEnum.disponibilidad} 
                                selected={selected} 
                                onClick={() => onHeaderClick(TitleEnum.disponibilidad)} 
                            />
                        </div>

                        {selected === TitleEnum.datos && (
                            <DatosPersonales values={values} touched={touched} errors={errors}>
                                <div className="form-map">
                                    <Map defaultDirection={values.datosPersonales.direccion} marker={defaultMarker} setMarker={setDefaultMarker}  />
                                    {touched.datosPersonales?.direccion && 
                                        errors.datosPersonales?.direccion && 
                                        <span className="form-error">{errors.datosPersonales.direccion}</span>
                                    }
                                </div>
                            </DatosPersonales>
                        )}

                        {selected === TitleEnum.formacion && <Formacion values={values} touched={touched} errors={errors} />}

                        {selected === TitleEnum.preferencias && <Preferencias values={values} touched={touched} errors={errors} />}

                        {selected === TitleEnum.disponibilidad && <Disponibilidad values={values} touched={touched} errors={errors} />}

                        <div className="form-button">
                            <button type='submit' className={loading ? 'title button_hold' : 'title'} disabled={loading}>
                                {loading ?  <img src={LoadingAnimation} style={{ height: '65px' }} /> : 'Continuar'}
                            </button>
                        </div>
                       
                    </Form>
                )}
            </Formik>

            <NotificationModal 
                errorMessage={errorMessage}
                onCancel={() => {
                    setLoading(false)
                    setErrorMessage({ header: '', body: ''})
                }}
            />
        </div>  
    );
}

const HeaderTitle = ({ text, selected, onClick }: { text: string, selected: string, onClick?: () => void }) => (
    <div className={`title${selected === text ? ' selected' : ''}`} onClick={onClick}>{text}</div>
)

/*
{
    "nombre": "Pedro",
    "apellidos": "Porro",
    "genero": "33905",
    "fechanacimiento": "1984-06-13",
    "modalidad": "34476",
    "datosPersonales": {
        "tipoDocumento": "33910",
        "tipoDocumentoValue": "asfvgdsgdsf",
        "lugarNacimiento": {
            "id": "33971",
            "value": "España"
        },
        "nacionalidad": {
            "id": "34352",
            "value": "Español"
        },
        "direccion": "C. de Santurce, 143, Cdad. Lineal, 28017 Madrid, España",
        "telefono1": "",
        "telefono2": "",
        "correoelectronico": "",
        "carnet": false,
        "situacionLabora": "34428",
        "profesion": "afdaf"
    },
    "formacion": {
        "nivelEstudios": "",
        "idiomas": "",
        "hablasEspanol": false,
        "experenciaAnterior": false,
        "experenciaLaboral": false,
        "sector": ""
    },
    "preferencias": {
        "nuestra": "34437",
        "motivacionparaCambio": "sfadfadffafdf",
        "hobby": false,
        "hobbyValue": "",
        "perfilAtencion": "34444",
        "areaTrabajo": "34456",
        "observaciones": ""
    },
    "disponibilidad": {
        "lunes": false,
        "martes": false,
        "miercoles": false,
        "jueves": false,
        "viernes": false,
        "sabado": false,
        "domingo": false,
        "horarios": {
            "manana": false,
            "tarde": false,
            "frecuencia": "34458",
            "dedicacion": "",
            "preferenciacontacto": "",
            "tipoVoluntariado": "",
            "observaciones": ""
        }
    }
}*/